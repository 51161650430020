const context = require.context('./', true, /.svg/);

// Dynamically build an object containing all files from folder

const obj = {};

context.keys().forEach(filePath => {
  // Remove the './' and './svg' from the object key
  obj[filePath.replace('./', '').replace('.svg', '')] = context(filePath);
});

export default obj;
