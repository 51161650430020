import React, { useEffect, useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { CheckoutButton, Link } from 'components';
import CartList from './CartList';
import { CartConsumer } from '../../context/cart';
import './Cart.scss';

const Cart = () => (
  <CartConsumer>
    {({ checkout, removeCartItem, updateCartItem, showCart, openCart, closeCart, cartLoading }) => {
      const handleRemove = async itemId => {
        await removeCartItem(itemId);
      };

      const handleQuantityChange = async (lineItemId, quantity) => {
        if (!quantity) return null;
        await updateCartItem(lineItemId, quantity);
      };

      const itemsInCart = checkout ? checkout.lineItems.reduce((total, item) => total + item.quantity, 0) : 0;

      return (
        <div className={`cart ${showCart ? 'open' : 'closed'} ${cartLoading ? 'loading' : ''} open`}>
          <div className="cart-toggle">
            <a
              href="#open-cart"
              className={`icon icon-shopping-bag-dark ${showCart ? 'open' : 'closed'}`}
              aria-label={`Shopping cart with ${itemsInCart} items`}
              onClick={showCart ? closeCart : openCart}
            >
              <div className="cart-toggle-count">{itemsInCart || '0'}</div>
            </a>
          </div>
          <CartDropDown
            showCart={showCart}
            itemsInCart={itemsInCart}
            checkout={checkout}
            handleRemove={handleRemove}
            handleQuantityChange={handleQuantityChange}
            cartLoading={cartLoading}
            closeCart={closeCart}
          />
        </div>
      );
    }}
  </CartConsumer>
);

const CartDropDown = props => {
  const { showCart, itemsInCart, checkout, handleRemove, handleQuantityChange, cartLoading, closeCart } = props;

  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    setCartOpen(showCart);
  }, [showCart]);

  const closeCartHandler = event => {
    if (event) event.preventDefault();
    setCartOpen(false);
    closeCart();
  };

  CartDropDown.handleClickOutside = () => {
    closeCartHandler();
  };

  return (
    <div className="cart-dropdown" style={{ display: cartOpen ? 'block' : 'none' }}>
      <h3 className="cart-dropdown-title">Your Cart</h3>
      {itemsInCart > 0 ? (
        <>
          <CartList
            items={checkout.lineItems}
            handleRemove={handleRemove}
            updateQuantity={handleQuantityChange}
            cartLoading={cartLoading}
          />
          <div className="cart-dropdown-actions">
            <Link to="#close" className="button outline" onClick={closeCartHandler}>
              Close Cart
            </Link>
            <CheckoutButton />
          </div>
        </>
      ) : (
        <p className="cart-dropdown-empty">Cart is empty</p>
      )}
    </div>
  );
};

Cart.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => CartDropDown.handleClickOutside,
};

export default onClickOutside(Cart, clickOutsideConfig);
