import React from 'react';
import { CartConsumer } from '../../context/cart';

const CheckoutButton = () => (
  <CartConsumer>
    {({ checkout }) => {
      if (!checkout || !checkout.webUrl) return null;
      return (
        <a className="button" href={checkout.webUrl}>
          Go to Checkout
        </a>
      );
    }}
  </CartConsumer>
);

export default CheckoutButton;
