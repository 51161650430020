import React from 'react';
import { Header, Footer } from 'components';
import { CartProvider } from '../../context/cart';
import 'typeface-montserrat';
import '../../sass/global/styles.scss';
import './Layout.scss';

export const Layout = props => {
  const { children, hideEscapeRoutes } = props;
  return (
    <CartProvider>
      {!hideEscapeRoutes && <Header />}
      <main>{children}</main>
      {!hideEscapeRoutes && <Footer />}
    </CartProvider>
  );
};
