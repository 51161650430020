import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'components';
import './BlogCard.scss';

export const BlogCard = props => {
  const { data, uid: slug } = props;
  const { featured_image: image, post_title: title, post_description: description } = data;
  return (
    <Link to={`/blog/${slug}`} class="link-wrapper">
      <div className="blog-card-wrapper">
        <div className="wrapper">
          <div className="image-container">
            <Image image={image} className="image-box" />
          </div>
          <h1 className="blog-card-title">{title.text}</h1>
          <p>{description.text}</p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
