import React, { useState, useEffect } from 'react';
import { Hamburger } from 'components';
import './HamburgerNav.scss';

const HamburgerNav = ({ className, children }) => {
  const [showNav, setShowNav] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);

  const toggleNav = event => {
    event.preventDefault();
    setShowNav(!showNav);
  };

  const handleEscKey = event => {
    if (event.keyCode === 27) {
      document.body.classList.remove('hamburger-nav-open');
      setShowNav(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('hamburger-nav-open');
    if (scrollPosition > 0) document.body.classList.add('scrolled-before-lock');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('hamburger-nav-open');
    document.body.classList.remove('scrolled-before-lock');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
      unlockBody();
    };
  }, []);

  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
  }, [showNav]);

  return (
    <div className={`hamburger-nav ${className || ''}`}>
      {children}
      <span className="hamburger-bg" />
      <Hamburger active={showNav} onClick={toggleNav} />
    </div>
  );
};

export default HamburgerNav;
