import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import './NewsletterSignup.scss';

const NewsletterSignup = ({ hideSubText }) => {
  const [value, setValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const onChange = event => setValue(event.target.value);

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitting(true);
    setSubmitError(false);
    setSubmitSuccess(false);
    const result = await addToMailchimp(value);
    if (result.result === 'success') {
      setSubmitting(false);
      setSubmitSuccess(true);
      setValue(null);
    } else {
      setSubmitting(false);
      setSubmitError(result.msg);
    }
  };

  return (
    <div className={`newsletter-signup ${value ? 'active' : ''}`}>
      <ErrorMessage submitError={submitError} />
      <SuccessMessage submitSuccess={submitSuccess} />
      {!submitSuccess && (
        <>
          <form name="subscribe" onSubmit={handleSubmit}>
            <div className="newsletter-signup-inputs">
              <input
                id="newsletter-sign-up"
                type="email"
                placeholder="Enter your email address"
                onChange={onChange}
                value={value}
                disabled={submitting}
                required
              />
              <button className="icon icon-plane-dark" type="submit" disabled={submitting}>
                Submit
              </button>
            </div>
          </form>
          {!hideSubText && (
            <label htmlFor="newsletter-sign-up" className="newsletter-signup-subtext">
              Signup for our newsletter and receive the latest product news and promotions
            </label>
          )}
        </>
      )}
    </div>
  );
};

const ErrorMessage = ({ submitError }) => {
  if (!submitError) return null;
  const alreadySubscribed = submitError.indexOf('already subscribed') || submitError.indexOf('too many requests');
  const message = alreadySubscribed
    ? "Looks like you're already subscribed to our mailing list."
    : 'Uh oh! Something went wrong!';
  return <span className="newsletter-signup-error">{message}</span>;
};

const SuccessMessage = ({ submitSuccess }) => {
  if (!submitSuccess) return null;
  return (
    <div className="newsletter-signup-success">
      <span>
        Thanks for joining the Safedome mailing list. Look out for latest product news and offers in your inbox.
      </span>
    </div>
  );
};

export default NewsletterSignup;
