import React, { useContext } from 'react';
import CartContext from '../../context/cart';

export const Price = ({ amount }) => {
  const { currency, exchangeRate } = useContext(CartContext);

  const price = Intl.NumberFormat(undefined, {
    currency: currency || 'USD',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(parseFloat(amount ? parseFloat(amount * (exchangeRate || 1)) : 0));

  return <span>{price}</span>;
};
