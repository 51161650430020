import React from 'react';
import { Image, Link, Price } from 'components';
import './ProductCard.scss';

export const ProductCard = ({ handle, title, description, images, useSecondImage }) => {
  // const {
  //   minVariantPrice: { amount: min },
  //   maxVariantPrice: { amount: max },
  // } = priceRange;
  //
  // const priceLabel = min !== max ? 'From: ' : '';

  const removeSafedome = str => {
    if (!str) {
      return null;
    }
    return str
      .replace('Safedome Mini', 'SD Mini')
      .replace('Safedome - ', '')
      .replace('Safedome', '');
  };

  const truncateString = (string, maxLength = 50) => {
    if (!string) return null;
    const showDots = string.length > maxLength;
    return `${string.substring(0, maxLength)}${showDots ? '...' : ''}`;
  };

  const imageIndexToUse = useSecondImage ? 1 : 0;

  return (
    <Link to={`/products/${handle}`} className="product-card">
      <Image image={images ? images[imageIndexToUse] : null} />
      <div className="product-card-info">
        <h4 className="product-card-title">{removeSafedome(title)}</h4>
        {/* <span className="product-card-price">
          {priceLabel} <Price amount={min} />
        </span> */}
        <div className="product-card-description">
          <p>{truncateString(description, 140)}</p>
        </div>
        <div className="product-card-actions">
          <span className="button primary">Learn More</span>
        </div>
      </div>
    </Link>
  );
};
