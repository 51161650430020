import React from 'react';
import './Hamburger.scss';

const Hamburger = ({ onClick, active, theme }) => (
  <button type="button" className={`hamburger ${active ? 'toggled' : ''} ${theme || ''}`} onClick={onClick}>
    Open Navigation
  </button>
);

export default Hamburger;
