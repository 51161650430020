import React, { useState } from 'react';
import { Price } from 'components';
import './CartListItem.scss';

const CartListItem = ({ item, updateQuantity, removeItem, cartLoading }) => {
  const [quantity, setQuantity] = useState(item.quantity || 1);

  const handleInputChange = event => {
    const { value } = event.target;
    setQuantity(value);
  };

  const handleQuanityUpdate = event => {
    if (cartLoading) return null;

    event.preventDefault();

    // Make sure the quantity is always at least 1.
    const safeValue = Math.max(Number(quantity), 0);

    // No need to update if the value hasn’t updated.
    if (safeValue === quantity) return null;

    // Otherwise set the quantity in state.
    setQuantity(safeValue);

    // If the quantity is set to 0, remove the item.
    if (safeValue === 0) {
      removeItem(item.id);
      return null;
    }

    // If we get here, update the quantity.
    updateQuantity(item.id, safeValue);
  };

  const handleRemoveItem = event => {
    event.preventDefault();
    removeItem(item.id);
  };

  const getResizedThumbnail = src => {
    if (!src) return null;
    const resizedSrc = src.replace('.png', '_160x.png');
    return resizedSrc;
  };

  return (
    <div className="cart-list-item">
      <div className="cart-list-item-image">
        <img src={getResizedThumbnail(item.variant.image.src)} alt={item.title} />
      </div>
      <div className="cart-list-item-info">
        <div className="cart-list-item-name">{item.title}</div>
        <div className="cart-list-item-meta">
          {item.variant.title}, <Price amount={item.variant.price} />
        </div>
        <div className="cart-list-item-actions">
          <input
            aria-label="Quantity"
            id={`quantity_${item.id.substring(58, 64)}`}
            type="number"
            name="quantity"
            inputMode="numeric"
            min="1"
            step="1"
            onChange={handleInputChange}
            value={quantity}
          />
          <a href="#remove" className="icon icon-update-dark" onClick={handleQuanityUpdate}>
            Update
          </a>
          <a href="#remove" className="icon icon-remove-dark" onClick={handleRemoveItem}>
            Remove
          </a>
        </div>
      </div>
    </div>
  );
};

export default CartListItem;
