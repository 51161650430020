import React, { useState } from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import slugify from 'slugify';
import { ArrowDownSvg } from '../Svg/ArrowDownSvg';
import './CategoryNav.scss';

export const CategoryNav = () => {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(allPostsQuery);

  const categoryFilters = posts.reduce((acc, curr) => {
    if (acc.indexOf(curr.data.category) < 0) {
      acc.push(curr.data.category);
    }
    return acc;
  }, []);

  // drop down menu

  const [dropdownToggle, setDropdownToggle] = useState(false);

  const menuToggle = () => {
    setDropdownToggle(!dropdownToggle);
  };

  const className = dropdownToggle === true ? 'dropdown-on' : '';

  const showDropdown = dropdownToggle === true ? 'block' : 'none';


  return (
    <div>
      <button type="button" onClick={menuToggle} className="button dropdown-button">
        Categories
        <ArrowDownSvg fill="#FFF" className={`arrow ${className}`} />
      </button>
      <div className="wrapper category-nav-wrapper" style={{ display: `${showDropdown}` }}>
        <div className={` dropdown-off ${className}`}>
          <Link to="/blog" onClick={menuToggle}>
            <div className="button">All</div>
          </Link>
          {categoryFilters.map(category => (
            <Link to={`/blog/${slugify(`${category}`, { lower: true })}`} onClick={menuToggle}>
              <div className="button">{category}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const allPostsQuery = graphql`
  query {
    posts: allPrismicBlogPost(sort: { order: DESC, fields: data___date_published }) {
      nodes {
        uid
        data {
          category
        }
      }
    }
  }
`;

export default CategoryNav;
