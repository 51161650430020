import React from 'react';
import { Link, NewsletterSignup, SocialLinks } from 'components';
import appStoreIcon from '../../images/app-store.svg';
import googlePlayIcon from '../../images/google-play.svg';
import './Footer.scss';

const navigations = {
  products: [
    { to: '/products/safedome-classic', label: 'Classic Card' },
    { to: '/products/safedome-recharge-card', label: 'Recharge Card' },
    { to: '/products/safedome-key-finder', label: 'Key Finder' },
    { to: '/products/clear', label: 'Clear Edition Card' },
    {
      to: '/products/safedome-recharge-bluetooth-trackersafedome-recharge-key-finder',
      label: 'Recharge Card & Key Finder',
    },
    { to: '/products/safedome-classic-key-finder', label: 'Classic Card & Key Finder' },
    { to: '/products/safedome-mini', label: 'SD Mini Finder' },
  ],
  b2b: [
    { to: '/for-business', label: 'Safedome for Business' },
    { to: '/certifications', label: 'Certifications' },
    { to: '/talk-to-us', label: 'Talk to Us' },
  ],
  about: [
    { to: '/faqs', label: 'FAQs' },
    { to: '/how-it-works', label: 'How it works' },
    { to: '/partners', label: 'Find Us' },
    { to: '/legal', label: 'Privacy Policy' },
    { to: '/returns-policy', label: 'Returns Policy' },
    { to: '/app-terms', label: 'App Terms' },
  ],
  sitemap: [
    { to: '/legal', label: 'Privacy Policy' },
    { to: '/app-terms', label: 'Terms of Service' },
  ],
};

export const Footer = () => (
  <footer className="footer">
    <div className="wrapper">
      <div className="footer-cols">
        <div className="footer-col footer-store-links">
          <h5 className="footer-col-title">Apps</h5>
          <div className="footer-col-links">
            <a
              href="https://apps.apple.com/au/app/safedome-find-my-things/id1040051283"
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label="Download on the App Store"
            >
              <img className="footer-store-icon" src={appStoreIcon} alt="Download on the App Store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.maxwellforest.safedomeapp"
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label="Get it on Google Play"
            >
              <img className="footer-store-icon" src={googlePlayIcon} alt="Get it on Google Play" />
            </a>
          </div>
        </div>
        <div className="footer-col">
          <h5 className="footer-col-title">Products</h5>
          <div className="footer-col-links">
            {navigations.products.map(link => (
              <Link key={link.label} className="footer-col-link" to={link.to}>
                {link.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-col">
          <h5 className="footer-col-title">B2B</h5>
          <div className="footer-col-links">
            {navigations.b2b.map(link => (
              <Link key={link.label} className="footer-col-link" to={link.to}>
                {link.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-col">
          <h5 className="footer-col-title">About</h5>
          <div className="footer-col-links">
            {navigations.about.map(link => (
              <Link key={link.label} className="footer-col-link" to={link.to}>
                {link.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-col footer-subscribe">
          <h5 className="footer-col-title">Subscribe</h5>
          <NewsletterSignup />
        </div>
      </div>
      <div className="sitemap">
        <div className="sitemap-left">
          <span className="sitemap-legal">© {new Date().getFullYear()} Safedome. All rights reserved</span>
          <nav className="sitemap-nav">
            {navigations.sitemap.map(link => (
              <Link key={link.label} className="sitemap-nav-link" to={link.to}>
                {link.label}
              </Link>
            ))}
          </nav>
        </div>
        <SocialLinks />
      </div>
    </div>
  </footer>
);
