import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { BlogCard } from '../BlogCard/BlogCard';
import './RecentPosts.scss';

export const RecentPosts = props => {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(recentPostsQuery);
  return <div className="blog-card-grid">{posts.length > 0 && posts.map(post => <BlogCard data={post.data} />)}</div>;
};

const recentPostsQuery = graphql`
  query {
    posts: allPrismicBlogPost(sort: { order: DESC, fields: data___date_published }, limit: 3) {
      nodes {
        uid
        data {
          featured_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          post_description {
            text
          }
          post_title {
            text
          }
        }
      }
    }
  }
`;
export default RecentPosts;
