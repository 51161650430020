import React from 'react';
import { ProductCard } from 'components';
import './ProductGrid.scss';

export const ProductGrid = ({ title, products }) => {
  return (
    <section className="product-grid">
      {title && (
        <div className="product-grid-header">
          <h3>{title}</h3>
        </div>
      )}
      <div className="product-grid-items">
        {products && products.length > 0 ? (
          products.map(product => <ProductCard key={product.shopifyId} {...product} />)
        ) : (
          <p>No products found</p>
        )}
      </div>
    </section>
  );
};
