import React, { useState } from 'react';
import { classNames, validate } from 'helpers';
import './TextArea.scss';

export const TextArea = props => {
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const { type = 'text', name, label, onChange, placeholder } = props;

  const check = () => {
    const message = validate(name, value);
    if (!value) return setError();
    if (message) return setError(message);
    setError();
  };

  const onChangeHandler = event => {
    const value = event && event.target && event.target.value;
    if (error) check();
    if (onChange) onChange(name, value);
    setValue(value);
  };

  const classes = classNames('textarea', { error });

  return (
    <div className={classes}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        type={type}
        name={name}
        placeholder={placeholder}
        onBlur={() => check(name)}
        onChange={event => onChangeHandler(event)}
      />
      {error && <span>{error}</span>}
    </div>
  );
};
