import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { BlogCard } from '../BlogCard/BlogCard';
import './Category.scss';

export const Category = props => {
  const { posts, sortedPosts, onLoadMore, numberOfPosts } = props;
  const postPagination = 10;
  const [visiblePosts, setVisiblePosts] = useState(postPagination);
  console.log(posts);

  return (
    <div className="category-grid-wrapper wrapper">
      <div className="blog-card-grid">
        {posts.length > 0 && posts.slice(0, visiblePosts).map(post => <BlogCard data={post.data} />)}
        <OnVisible onChange={() => setVisiblePosts(visiblePosts + postPagination)}  />
      </div>
    </div>
  );
};
