import React from 'react';
import { Cart, CurrencySwitcher, HamburgerNav, Logo, HeaderNavigation } from 'components';
import './Header.scss';

export const Header = () => (
  <header className="header">
    <div className="wrapper">
      <Logo />
      <HamburgerNav>
        <HeaderNavigation classNamePrefix="header" />
      </HamburgerNav>
      <div className="header-actions">
        <CurrencySwitcher />
        <Cart />
      </div>
    </div>
  </header>
);
