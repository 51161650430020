import React from 'react';
import { toSnakeCase, classNames } from 'helpers';
import * as Icons from './Icons';
import './Icon.scss';

export const Icon = props => {
  const { className, title, type = 'image' } = props;
  const classes = classNames(className, 'icons');
  if (!title) return null;
  const Source = Icons[toSnakeCase(title)];
  if (!Source) return null;
  const icon = type === 'svg' ? <Source title={title} /> : <img src={Source} title={title} />;
  return <div className={classes}>{icon}</div>;
};
