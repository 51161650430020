import React, { Fragment } from 'react';
import { Link, MegaNav } from 'components';
import './HeaderNavigation.scss';

const links = [
  { to: '/', text: 'Home', className: '' },
  {
    to: '#',
    text: 'Products',
    className: '',
    hasMegaDropDown: true,
    onClick: event => event.preventDefault(),
  },
  { to: '/how-it-works', text: 'How it Works', className: '' },
  { to: '/for-business', text: 'For Business', className: '' },
];

export const HeaderNavigation = () => {
  return (
    <nav className="header-navigation">
      {links.map(link => {
        const hasDropDown = link.hasMegaDropDown || link.subLinks;
        const className = `header-navigation-link ${link.className || ''}${hasDropDown ? ' has-dropdown' : ''}`;
        return (
          <div key={link.text} className={className}>
            <Link to={link.to} onClick={link.onClick}>
              {link.text}
            </Link>
            {link.hasMegaDropDown && <MegaNav />}
          </div>
        );
      })}
    </nav>
  );
};
