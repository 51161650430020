module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Bluetooth Tracking Devices | Bluetooth Trackers | Safedome', // Default Site Title used for SEO & PWA
  description: 'Safedome makes looking after what&#39;s valuable to you, easy. Bluetooth trackers easily which attach to keys, phones, wallets &amp; other valuables. Shop online today.', // Default Site Decription used for SEO
  siteName: 'Safedome', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language
  currencies: ['USD', 'EUR', 'GBP', 'AUD', 'HKD'],

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Safedome', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Safedome', // Author for schemaORGJSONLD
  themeColor: '#32BCC8',
  backgroundColor: '#07213C',

  twitter: '@safedome',
  googleTagManagerId: 'GTM-WVWHCJM',
};
