import React, { useState } from 'react';
import { classNames, validate, capitalise } from 'helpers';
import './TextField.scss';

export const TextField = props => {
  const [value, setValue] = useState();
  let [error, setError] = useState(null);
  const { type = 'text', name, label, onChange, onFocus, placeholder, className, fields } = props;

  let required = false;

  if (fields) {
    const field = fields[name];
    if (!field) return null;
    error = field && field.error;
    required = field && field.required;
  }

  const check = () => {
    if (!value && required) {
      const messageRequired = `${capitalise(name)} is required.`;
      setError(messageRequired);
      if (onChange) onChange(name, value, messageRequired);
      return;
    }

    const message = validate(name, value);
    if (message) {
      setError(message);
      if (onChange) onChange(name, value, message);
      return;
    }

    setError();
    if (onChange) onChange(name, value);
  };

  const onChangeHandler = event => {
    const fieldValue = event && event.target && event.target.value;
    if (error) check();
    if (onChange) onChange(name, fieldValue, error);
    setValue(fieldValue);
  };

  const onFocusHandler = event => {
    const fieldValue = event && event.target && event.target.value;
    if (onFocus) onFocus(name, fieldValue);
  };

  const classes = classNames('textfield', className, { error });

  return (
    <div className={classes}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        required={required}
        placeholder={placeholder}
        onBlur={() => check(name)}
        onChange={event => onChangeHandler(event)}
        onFocus={event => onFocusHandler(event)}
      />
      {error && <span>{error}</span>}
    </div>
  );
};
