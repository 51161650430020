import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import Client from 'shopify-buy';
import { currencies } from '../../config/website';

const client = Client.buildClient({
  domain: process.env.SHOP_URL || `${process.env.SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
});

const CHECKOUT_SESSION_ID = 'shopify_checkout_id';
const CURRENCY_SESSION_ID = 'shopify_currency';
const EXCHANGE_SESSION_ID = 'shopify_exchange_rate';

const defaultCartContext = {
  client,
  checkout: { lineItems: [] },
  cartLoading: false,
  showCart: false,
  currency: null,
  exchangeRate: null,
  fetchExchangeRate: () => console.log('not up to date'),
  addToCart: () => console.log('not up to date'),
  removeCartItem: () => {},
  updateCartItem: () => {},
  openCart: () => {},
  closeCart: () => {},
};

const CartContext = createContext(defaultCartContext);

const isBrowser = typeof window !== 'undefined';

export const CartProvider = ({ children }) => {
  const sessionCurrencyData = isBrowser ? localStorage.getItem(CURRENCY_SESSION_ID) : null;
  const sessionExchangeRate = isBrowser ? sessionStorage.getItem(EXCHANGE_SESSION_ID) : null;

  const [checkout, setCheckout] = useState(null);
  const [currency, setCurrency] = useState(sessionCurrencyData);
  const [exchangeRate, setExchangeRate] = useState(sessionExchangeRate);
  const [cartLoading, setCartLoading] = useState(false);
  const [showCart, setShowCart] = useState(false);

  // Fetch checkout data on mount
  useEffect(() => {
    if (isBrowser) {
      const existingCheckoutID = localStorage.getItem(CHECKOUT_SESSION_ID);
      if (existingCheckoutID && existingCheckoutID !== 'null') {
        fetchCheckout(existingCheckoutID);
      } else {
        createNewCheckout();
      }
    }
  }, []);

  // Fetch user's current when checkout has been set to state
  useEffect(() => {
    if (isBrowser && checkout && !currency) {
      fetchUserCurrency(checkout.currencyCode);
    }
  }, [checkout]);

  // Fetch user's exchange rate once currency has been set to state
  useEffect(() => {
    if (isBrowser && checkout && currency && !exchangeRate) {
      fetchExchangeRate(currency);
    }
  }, [checkout, currency]);

  // Fetch checkout session data from Shopify

  // Create new checkout if session id does not exist
  const createNewCheckout = async () => {
    try {
      const data = await client.checkout.create();
      localStorage.setItem(CHECKOUT_SESSION_ID, data.id);
      setCheckout(data);
    } catch (error) {
      console.error('Error creating new cart', error);
      localStorage.removeItem(CHECKOUT_SESSION_ID);
    }
  };

  // Fetch existing checkout if session ID exists
  const fetchCheckout = async id => {
    try {
      const data = await client.checkout.fetch(id);
      if (!data.completedAt) {
        setCheckout(data);
      }
    } catch (error) {
      console.error('Error fetching checkout', error);
      localStorage.setItem(CHECKOUT_SESSION_ID, null);
    }
  };

  // Fetch user geolocation and exchange rate using third party APIs
  const fetchUserCurrency = async defaultCurrency => {
    try {
      // Only query geolocation API if more than one currency existing in config array
      // and the data does not exist in local storage
      if (currencies && currencies.length > 0 && !sessionCurrencyData) {
        const geoApiUrl = 'https://ipapi.co/json';
        const { data: geoData } = await axios.get(geoApiUrl);
        if (geoData && geoData.currency) {
          // If currency code comes back from the API set it to state and store in local storage
          const currencyCodeToUse = currencies.indexOf(geoData.currency) !== -1 ? geoData.currency : 'USD';
          saveCurrencyToState(currencyCodeToUse);
        }
      } else {
        // If session data exists or single currency store set the state and store in session
        const currencyCodeToUse = sessionCurrencyData || defaultCurrency;
        saveCurrencyToState(currencyCodeToUse);
      }
    } catch (error) {
      console.error('Error fetching users currency code', error);
      setCurrency(defaultCurrency);
    }
  };

  const fetchExchangeRate = async (userCurrencyCode, forcedUpdate) => {
    try {
      // If session data exists or user's currency matches default store currency skip API request
      if ((!forcedUpdate && sessionExchangeRate) || checkout.currencyCode === userCurrencyCode) {
        const exchangeRateToUse = checkout.currencyCode === userCurrencyCode ? 1 : sessionExchangeRate;
        saveExhangeRateToState(exchangeRateToUse);
        // Set currency passed to state and local storage (useful when currency is updated on FE)
        saveCurrencyToState(userCurrencyCode);
      } else {
        // Make API request to fetch exhange rate for user's currency
        const exchangeRateApi = `https://api.exchangeratesapi.io/latest?symbols=${userCurrencyCode}&base=${checkout.currencyCode}`;
        const ratesData = await axios.get(exchangeRateApi);
        if (ratesData && ratesData.data && ratesData.data.rates) {
          // Set rate to state and persist in session storage (wipes after window is close to keep rate up to data)
          const exchangeRateToUse = ratesData.data.rates[userCurrencyCode];
          saveExhangeRateToState(exchangeRateToUse);
          // Set currency passed to state and local storage (useful when currency is updated on FE)
          saveCurrencyToState(userCurrencyCode);
        }
      }
    } catch (error) {
      console.error('Error fetching exchange rate', error);
      setExchangeRate(1);
    }
  };

  const saveCurrencyToState = curr => {
    setCurrency(curr);
    localStorage.setItem(CURRENCY_SESSION_ID, curr);
  };

  const saveExhangeRateToState = rate => {
    console.log('rate', rate);
    setExchangeRate(rate);
    sessionStorage.setItem(EXCHANGE_SESSION_ID, rate);
  };

  // Checkout methods
  // Add to cart
  const addToCart = async (variantId, quantity) => {
    console.log('adding to cart');
    if (variantId === '' || !quantity) {
      return console.error('Both a size and quantity are required.');
    }
    setCartLoading(true);
    const checkoutId = checkout.id;
    const lineItemsToUpdate = [{ variantId, quantity: parseInt(quantity, 10) }];
    const newCheckout = await client.checkout.addLineItems(checkoutId, lineItemsToUpdate);
    setCheckout(newCheckout);
    setCartLoading(false);
    setShowCart(true);
  };

  // Remove from cart
  const removeCartItem = async lineItemID => {
    setCartLoading(true);
    const checkoutId = checkout.id;
    const newCheckout = await client.checkout.removeLineItems(checkoutId, [lineItemID]);
    setCheckout(newCheckout);
    setCartLoading(false);
  };

  // Update cart item
  const updateCartItem = async (lineItemID, quantity) => {
    setCartLoading(true);
    const checkoutId = checkout.id;
    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];
    const newCheckout = await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
    setCheckout(newCheckout);
    setCartLoading(false);
  };

  // Toggle mini cart UI
  const openCart = event => {
    if (event) event.preventDefault();
    setShowCart(true);
  };

  const closeCart = event => {
    if (event) event.preventDefault();
    setShowCart(false);
  };

  return (
    <CartContext.Provider
      value={{
        ...defaultCartContext,
        checkout,
        currency,
        exchangeRate,
        fetchExchangeRate,
        addToCart,
        cartLoading,
        removeCartItem,
        updateCartItem,
        openCart,
        closeCart,
        showCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
export const CartConsumer = CartContext.Consumer;

export default CartContext;
