import React from 'react';
import CartListItem from './CartListItem';
import './CartList.scss';

const CartList = ({ items, handleRemove, updateQuantity, cartLoading }) => (
  <div className="cart-list">
    <div className="cart-list-body">
      {items.map(item => (
        <CartListItem
          key={item.id}
          item={item}
          removeItem={handleRemove}
          updateQuantity={updateQuantity}
          cartLoading={cartLoading}
        />
      ))}
    </div>
  </div>
);

export default CartList;
