import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Link } from 'components';
import { CartConsumer } from '../../context/cart';
import { currencies } from '../../../config/website';
import countryFlags from '../../images/currencies';
import './CurrencySwitcher.scss';

const CurrencySwitcher = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState(null);

  const openDropDown = event => {
    if (event) event.preventDefault();
    setShowDropDown(true);
  };

  const closeDropDown = event => {
    if (event) event.preventDefault();
    setShowDropDown(false);
  };

  CurrencySwitcher.handleClickOutside = () => {
    if (showDropDown) closeDropDown();
  };

  return (
    <div>
      <CartConsumer>
        {({ currency, fetchExchangeRate }) => {
          if (!currencies || currencies.length === 1) return null;

          setActiveCurrency(currency);

          const selectCurrency = (event, selectedCurrency) => {
            event.preventDefault();
            fetchExchangeRate(selectedCurrency, true);
            closeDropDown();
          };

          if (!activeCurrency) return null;

          return (
            <div className={`currency-switcher ${showDropDown ? 'open' : 'closed'}`}>
              <a
                href="#open-currencies"
                className="currency-toggle"
                aria-label="Open currency switcher"
                onClick={showDropDown ? closeDropDown : openDropDown}
              >
                <img src={countryFlags[activeCurrency]} alt="country_flag" />
                <span className={`currency-toggle-selected ${showDropDown ? 'open' : 'closed'}`}>{activeCurrency}</span>
              </a>
              <div className="currency-dropdown" style={{ display: showDropDown ? 'block' : 'none' }}>
                {currencies
                  .filter(currencyLabel => currencyLabel !== activeCurrency)
                  .map(currencyLabel => (
                    <Link
                      key={currencyLabel}
                      className="currency-dropdown-option"
                      to="#set-currency"
                      onClick={event => selectCurrency(event, currencyLabel)}
                    >
                      <img src={countryFlags[currencyLabel]} alt="country_flag" />
                      {currencyLabel}
                    </Link>
                  ))}
              </div>
            </div>
          );
        }}
      </CartConsumer>
    </div>
  );
};

CurrencySwitcher.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => CurrencySwitcher.handleClickOutside,
};

export default onClickOutside(CurrencySwitcher, clickOutsideConfig);
