import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Image, Link, ProductCard } from 'components';
import './MegaNav.scss';

export const MegaNav = () => {
  const [openColumn, setOpenColumn] = useState(null);

  const {
    popularProductsQuery,
    featuredProductQuery,
    comboProductsQuery,
    accessoryProductsQuery,
    carbonFiberProductsQuery,
  } = useStaticQuery(popularQuery);

  const { products: popularProducts } = popularProductsQuery.edges[0].node;
  const { products: featuredProduct } = featuredProductQuery.edges[0].node;
  const { products: comboProducts } = comboProductsQuery.edges[0].node;
  const { products: accessoryProducts } = accessoryProductsQuery.edges[0].node;
  const { products: carbonFiberProducts } = carbonFiberProductsQuery.edges[0].node;

  const removeSafedome = str => {
    if (!str) {
      return null;
    }
    return str
      .replace('Safedome Mini', 'SD Mini')
      .replace('Safedome - ', '')
      .replace('Safedome', '');
  };

  const removeUsOnly = str => {
    if (!str) {
      return null;
    }
    return str.replace(' ( only available in the U.S )', '');
  };

  const navCols = [
    { tagline: 'Great value', title: 'Combos', products: comboProducts },
    { tagline: 'Partners in crime', title: 'Accessories', products: accessoryProducts },
    { tagline: 'Cutting edge', title: 'Carbon Fiber', products: carbonFiberProducts },
    { title: 'How it Works', url: '/how-it-works', hamburgerOnly: true },
    { title: 'For Business', url: '/for-business', hamburgerOnly: true },
  ];

  const toggleColumn = (event, index) => {
    if (openColumn === index) return setOpenColumn(null);
    return setOpenColumn(index);
  };

  return (
    <div className="mega-nav">
      <div className="wrapper">
        <div className="mega-nav-left">
          <div className="mega-nav-popular">
            <span className="mega-nav-title">Popular Products</span>
            <div className="mega-nav-popular-grid">
              {popularProducts.map(product => (
                <Link to={`/products/${product.handle}`} className="mega-nav-popular-item" key={product.handle}>
                  <Image image={product.images[0]} />
                  <span className="mega-nav-popular-item-title">{removeSafedome(product.title)}</span>
                </Link>
              ))}
            </div>
          </div>
          <div className="mega-nav-cols">
            {navCols.map((column, index) => {
              if (column.hamburgerOnly) {
                return (
                  <div className="mega-nav-col hamburger-only" key={column.title}>
                    <Link to={column.url} className="mega-nav-col-header">
                      <span className="mega-nav-col-title">{column.title}</span>
                    </Link>
                  </div>
                );
              }
              return (
                <div className={`mega-nav-col ${openColumn === index ? 'open' : 'closed'}`} key={column.title}>
                  <div className="mega-nav-col-header" role="button" onClick={event => toggleColumn(event, index)}>
                    <span className="tagline small">{column.tagline}</span>
                    <span className="mega-nav-col-title">{column.title}</span>
                  </div>
                  <nav className="mega-nav-col-links">
                    {column.products.map(product => (
                      <Link to={`/products/${product.handle}`} className="mega-nav-col-link" key={product.handle}>
                        {removeSafedome(removeUsOnly(product.title))}
                      </Link>
                    ))}
                  </nav>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mega-nav-right">
          <span className="mega-nav-title">Top Seller</span>
          <ProductCard {...featuredProduct[0]} useSecondImage />
        </div>
      </div>
    </div>
  );
};

const popularQuery = graphql`
  {
    popularProductsQuery: allShopifyCollection(filter: { title: { eq: "Mega Nav - Popular Products" } }, limit: 6) {
      edges {
        node {
          products {
            title
            handle
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredProductQuery: allShopifyCollection(filter: { title: { eq: "Mega Nav - Featured Product" } }, limit: 1) {
      edges {
        node {
          products {
            title
            handle
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
          }
        }
      }
    }
    comboProductsQuery: allShopifyCollection(filter: { title: { eq: "Combo Packs" } }) {
      edges {
        node {
          products {
            title
            handle
          }
        }
      }
    }
    accessoryProductsQuery: allShopifyCollection(filter: { title: { eq: "Accessories" } }) {
      edges {
        node {
          products {
            title
            handle
          }
        }
      }
    }
    accessoryProductsQuery: allShopifyCollection(filter: { title: { eq: "Accessories" } }) {
      edges {
        node {
          products {
            title
            handle
          }
        }
      }
    }
    carbonFiberProductsQuery: allShopifyCollection(filter: { title: { eq: "Carbon Fiber" } }) {
      edges {
        node {
          products {
            title
            handle
          }
        }
      }
    }
  }
`;
