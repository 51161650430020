import React from 'react';
import icons from '../../images/icons';
import './SocialLinks.scss';

const socialLinks = [
  { to: 'https://www.facebook.com/safedome', label: 'Facebook', icon: 'facebook-primary' },
  { to: 'https://twitter.com/safedome', label: 'Twitter', icon: 'twitter-primary' },
  { to: 'https://www.instagram.com/getsafedome/', label: 'instagram', icon: 'instagram-primary' },
];

const SocialLinks = () => (
  <nav className="social-links">
    {socialLinks.map(link => (
      <a key={link.to} href={link.to} aria-label={link.label} rel="noopener" className="social-link">
        <img src={icons[link.icon]} alt={link.label} />
      </a>
    ))}
  </nav>
);

export default SocialLinks;
