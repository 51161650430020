import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { classNames } from 'helpers';
import './CarouselSlick.scss';
import './CarouselSlickDefault.scss';
import './CarouselSlickTheme.scss';

export const CarouselSlick = props => {
  const { children, className, sliderRef, settings, ...sliderProps } = props;
  const classes = classNames('carousel-slick', className);

  // Pass sliderRef to parent component
  const sliderElement = useRef(null);

  useEffect(() => {
    if (sliderRef) {
      sliderRef(sliderElement);
    }
  }, [sliderRef]);

  // Slider
  return (
    <div className={classes}>
      <Slider {...settings} {...sliderProps} ref={sliderRef ? sliderElement : null}>
        {children}
      </Slider>
    </div>
  );
};
