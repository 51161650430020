import React, { useRef, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useScript, classNames } from 'helpers';
import MapsStyle from './MapsStyle/MapsStyle';
import './Maps.scss';

const apiKey = 'AIzaSyCjy1eu4wmTfvay87jpP931Skm9wV8zk88';

export const Maps = props => {
  const { coordinates, zoom, children, mapsRef, className } = props;
  const [loaded, error] = useScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`);
  const classes = classNames('Maps', className);
  return (
    <div className={classes}>
      {loaded && (
        <GoogleMap
          zoom={zoom}
          onLoad={map => mapsRef(map)}
          center={coordinates}
          options={{ styles: MapsStyle, mapTypeControl: false, fullscreenControl: false, streetViewControl: false }}
        >
          {children}
          {coordinates && <Marker position={coordinates} />}
        </GoogleMap>
      )}
    </div>
  );
};
